.program_detail_section_wrapper {
    .program_section_wrapper {
        gap: 24px;
        padding: 20px;


        .program_grid {
            border-radius: 20px;
            padding: 20px 24px;
            gap: 20px;
            position: relative;

            .program_grid_image {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background: transparent;
                background-image: url('./../../../../../public/images/discovery/discoveryGrid.png');
            }

            .top_flex {
                gap: 12px;

                .tags_wrapper {
                    gap: 13px;
                }
            }

            .buttons_wrapper {
                gap: 16px;
                z-index: 11;

                .apply_now {
                    height: 54px;
                    border-radius: 12px;
                    background: #FFC900;
                    padding: 16px 20px;
                    gap: 8px;
                    cursor: pointer;
                }

                .download_broc {
                    border-radius: 12px;
                    padding: 16px 20px;
                    gap: 8px;
                    background-color: white;
                }
            }

            .element_wrppr {
                background-color: #fff;
                align-items: center;
                border-radius: 12px;
                min-height: 54px;
                display: flex;
                padding: 15px 24px;
                gap: 10px;
                cursor: pointer;
            }
        }
    }

    .mentor_section {
        .mentor_image {
            min-width: 52px;
            max-width: 52px;
            height: 52px;
            img {
                -webkit-appearance: none !important;
                max-width: 52px !important;
                -moz-appearance: none !important;
                appearance: none !important;
            }
        }
    }

    .program_details_section {
        div {
            font-family: var(--PLEX_SANS);
        }
    }
}

@media screen and (max-width: 800px) {
    .program_detail_section_wrapper {
        .program_section_wrapper {
            gap: 32px;
            padding: unset;
            margin-bottom: 120px;

            .program_grid {
                border-radius: unset;
                align-items: center;

                .top_flex {
                    .tags_wrapper {
                        justify-content: center;
                    }
                }

                .program_headings_wrapper,
                .buttons_wrapper {
                    justify-content: center;
                    align-items: center;
                    z-index: 10;

                    .heading {
                        text-align: center;
                    }
                }
            }
        }
    }
}