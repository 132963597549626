.button_wrapper_more {
    position: relative;

    .more_dropdown {
        background-color: #fff;
        align-items: center;
        border-radius: 12px;
        min-height: 54px;

        .more_text {
            padding: 0px 20px;
            border-radius: 12px 0px 0px 12px;
        }

        .svg_wrapper {
            border-radius: 0px 12px 12px 0px;
            border-left: 1px solid #2F2F2F;
            padding: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }

    .drop_down_dark_screen {

        .dropdown_content {
            position: absolute;
            top: 61px;
            min-width: 192px;
            border-radius: 16px;
            border: 1px solid #E2E2E3;
            background-color: #fff;

            .dropdown_item {
                display: flex;
                gap: 10px;
                padding: 16px 20px;
                cursor: pointer;
            }

            .close_icon_wrapper {
                display: none;
            }
        }

        .dropdown_content li {
            border-top: 1px solid #E2E2E3;
            min-width: max-content;
        }

        .dropdown_content li:nth-child(2) {
            border: unset !important;
        }
    }
}

@media screen and (max-width: 800px) {
    .button_wrapper_more {
        position: unset;

        .drop_down_dark_screen {
            position: fixed;
            z-index: 11;
            width: 100%;
            top: 0;
            left: 0;
            min-height: -webkit-fill-available;
            display: flex;
            align-items: flex-end;
            background-color: rgba(0, 0, 0, 0.50);
            .dropdown_content {
                border-bottom-left-radius: unset;
                border-bottom-right-radius: unset;
                width: 100%;
                position: unset !important;

                .close_icon_wrapper {
                    display: flex;
                    padding: 14px 12px;
                    justify-content: flex-end;
                }

                .dropdown_item {
                    justify-content: center;
                    padding: 24px 34px;
                }
            }
        }
    }

}